import * as React from "react"
import HeroSection from "../components/HeroSection"
import Layout from "../components/Layout"

function IndexPage() {
  return (
    <Layout>
      <HeroSection/>
    </Layout>
  )
}

export default IndexPage

export const Head = () => (
  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
    <title>Home Page</title>
  </head>
)
