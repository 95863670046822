import React from 'react'
import {
  createStyles,
  Image,
  Container,
  Title,
  Button,
  Group,
  Text,
  List,
  ThemeIcon,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCheck } from '@fortawesome/free-solid-svg-icons'

const useStyles = createStyles((theme) => ({
  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing.xl * 4,
    paddingBottom: theme.spacing.xl * 4,
  },

  content: {
    maxWidth: 480,
    marginRight: theme.spacing.xl * 3,

    [theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
      marginRight: 0,
    },
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 44,
    lineHeight: 1.3,
    fontWeight: 900,

    [theme.fn.smallerThan('xs')]: {
      fontSize: 28,
    },
  },

  control: {
    [theme.fn.smallerThan('xs')]: {
      flex: 1,
    },
  },

  image: {
    flex: 1,

    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  highlight: {
    position: 'relative',
    backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
    borderRadius: theme.radius.sm,
    padding: '4px 12px',
  },
}));

export default function HeroSection() {
  const { classes } = useStyles();
  return (
    <div>
      <Container>
        <div className={classes.inner}>
          <div className={classes.content}>
            <Title className={classes.title}>
              Hi, I'm <span className={classes.highlight}>Roy Waswa,</span><br/>a self taught web developer
            </Title>
            <Text color="dimmed" mt="md">
              I am a front-end web developer, but I also do some dev ops and backend stuff. To be specific 
              I have done some of the following:
            </Text>

            <List
              mt={30}
              spacing="sm"
              size="sm"
              icon={
                <ThemeIcon size={20} radius="xl">
                  <FontAwesomeIcon icon={faCheck}  size={12} stroke={1.5} />
                </ThemeIcon>
              }
            >
              <List.Item>
                <b>Vanilla Javascript</b> – It is always good to be able to pull off a website of any magnitute with the good ol' Trinity (HTML, CSS, JS)
              </List.Item>
              <List.Item>
                <b>JavaScript Frameworks</b> – For SPA(single page applications) I have grown a fondness for React.js and Vue JS. And static site generators like GatsbyJS and NextJs.
              </List.Item>
              <List.Item>
                <b>Firebase Products</b> –I beleive that any well developed project will need to use server side services, I have found Firebase to be a great choice.
              </List.Item>
            </List>

            <Text color="dimmed" mt="md">
              I have done a couple of projects on some of these technologies.
            </Text>
            <Group mt={30}>
              <Button radius="xl" size="md" className={classes.control}>
                Explore Some Projects
              </Button>
              <Button variant="default" radius="xl" size="md" className={classes.control}>
                Check Me Out On Github
              </Button>
            </Group>
          </div>
          <Image src='' className={classes.image} />
        </div>
      </Container>
    </div>
  );
}